const menus = [
    {
        id: 0,
        tomenu: "#",
        namemenu: "Home"
    },
    {
        id: 1,
        tomenu: "#shop",
        namemenu: "Shop"
    },
    {
        id: 2,
        tomenu: "#books",
        namemenu: "Books"
    },
    {
        id: 3,
        tomenu: "#reviews",
        namemenu: "reviews"
    },
    {
        id: 4,
        tomenu: "#about",
        namemenu: "About"
    },
];



export default menus ;